
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useRouter } from "vue-router";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "finance-undewriter-details",
  components: {
    GoBackButton,
  },
  data() {
    return {
      summary: {},
      creditNotes: [
        {
          serialNumber: "",
          policyNumber: "",
          ibnPolicyNumber: "",
          businessType: "",
          amount: 0,
          status: "",
          transactionDate: "",
          policyPublicId: "",
          publicId: "",
          commission: 0,
          vat: 0,
          premium: 0,
          net: 0,
          clientName: "",
          policyReference: "",
          paymentPublicId: "",
        },
      ],
      publicId: this.$route.params.publicId,
      link: "",
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Underwriter Details", [
        "Users",
        "Underwriters",
      ]);

      displayDatatable(["basicExample"]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab,
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }
    // Get policy information
    this.getPolicyInformation();

    //Set page title
    document.title = "Underwriter Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getPolicyInformation() {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.FINANCE_UNDERWRITERS_DETAILS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.summary = data.data.summary;
            this.creditNotes = data.data.creditNotes;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    gotoUrl(event) {
      this.$router.push(event);
    },
  },
});
